import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Button, Container, Grid, List, ListItem, ListItemText, Divider } from '@mui/material';
import AppAppBar from '../../GeneralComponents/AppAppBar';
import Footer from './components/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Theme from '../../GeneralComponents/Theme';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const commands = [
  { name: 'init', description: 'Sets up your wallet for the first time. This command prepares your wallet by creating the necessary local storage. Think of it as setting up a new account where your digital assets will be stored.' },
  { name: 'sync', description: 'Updates your wallet with the latest information. This command connects to the blockchain to ensure your wallet has the most recent data. It\'s like refreshing your email inbox to see new messages.' },
  { name: 'status', description: 'Shows the current state of your wallet. This command provides a summary of your wallet\'s current status, including whether it\'s up-to-date with the blockchain. It\'s like checking your bank account balance to see your current funds.' },
  { name: 'list [owner_id]', description: 'Displays transactions for a specific owner. By entering an owner\'s ID, this command shows all the digital asset transactions associated with that ID. It\'s similar to viewing a statement of all transactions for a specific account.' },
  { name: 'reset', description: 'Clears and resets your wallet. This command wipes your wallet\'s data and sets it up anew. It\'s like resetting a device to its factory settings, erasing all current data.' },
  { name: 'verify', description: 'Confirms the authenticity of a digital asset. This command checks if a digital asset and its transactions are genuine. It\'s akin to verifying the authenticity of a signed document.' },
  { name: 'serve', description: 'Shares your blockchain data with others. This command allows your local blockchain data to be accessed by others, like hosting a file on a server for others to download.' },
  { name: 'sync-from [ipaddress]', description: 'Updates your wallet using data from another computer. By providing an IP address, this command syncs your wallet with data from another computer, similar to downloading updates from a trusted source.' },
  { name: 'view [owner_id]', description: 'Shows images of digital assets for a specific owner. This command displays images of digital assets associated with a given owner\'s ID, like browsing through a photo album of your collectibles.' },
  { name: 'version', description: 'Displays the current version of the application. This command shows which version of the application you are using, similar to checking the version number of an app on your phone.' },
];

const BlockchainPage = () => {
  const [value, setValue] = useState(0);
  const [mode, setMode] = useState("light");
  const theme = createTheme(Theme(mode));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleDownload = async (platform) => {
    try {
      const response = await fetch(`/api/download/${platform}`);
      const data = await response.json();
      window.location.href = data.downloadUrl;
    } catch (error) {
      console.error("Error downloading app:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          MyWallet CLI Application
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Download and install the MyWallet CLI Application to interact with the NFT Solutions blockchain.
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="platform tabs">
          <Tab icon={<i className="fab fa-linux"></i>} label="Linux" />
          <Tab icon={<i className="fab fa-apple"></i>} label="macOS" />
          <Tab icon={<i className="fab fa-windows"></i>} label="Windows" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant="h6">Linux Installation</Typography>
          <ol>
            <li>Open a terminal and navigate to the directory containing your mywallet executable.</li>
            <li>Execute the following command to install mywallet globally:</li>
            <pre><code>./mywallet-linux</code></pre>
            <li>Follow the prompt and type <code>y</code> to confirm the installation.</li>
            <li>If needed, manually add <code>~/.local/bin</code> to your PATH.</li>
            <li>Verify installation by running <code>mywallet</code> from any terminal window.</li>
          </ol>
          <Button variant="contained" onClick={() => handleDownload('linux')}>
            Download for Linux
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6">macOS Installation</Typography>
          <ol>
            <li>Open Terminal and navigate to the directory containing your mywallet executable.</li>
            <li>Execute the following command to install mywallet globally:</li>
            <pre><code>./mywallet-macos</code></pre>
            <li>Follow the prompt and type <code>y</code> to confirm the installation.</li>
            <li>If needed, manually add <code>~/Applications</code> to your PATH.</li>
            <li>Verify installation by running <code>mywallet</code> from any terminal window.</li>
          </ol>
          <Button variant="contained" onClick={() => handleDownload('macos')}>
            Download for macOS
          </Button>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6">Windows Installation</Typography>
          <ol>
            <li>Open Command Prompt and navigate to the directory containing your mywallet.exe.</li>
            <li>Execute the following command to install mywallet globally:</li>
            <pre><code>mywallet-windows.exe</code></pre>
            <li>Follow the prompt and type <code>y</code> to confirm the installation.</li>
            <li>If needed, manually add the installation directory to your PATH.</li>
            <li>Verify installation by running <code>mywallet</code> from any Command Prompt window.</li>
          </ol>
          <Button variant="contained" onClick={() => handleDownload('windows')}>
            Download for Windows
          </Button>
        </TabPanel>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={3}>
            <List component="nav" aria-label="command navigation">
              {commands.map((command, index) => (
                <ListItem button key={index} component="a" href={`#${command.name}`}>
                  <ListItemText primary={command.name} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            {commands.map((command, index) => (
              <Box key={index} id={command.name} sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  {command.name}
                </Typography>
                <Typography variant="body1" paragraph>
                  {command.description}
                </Typography>
                <Divider />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default BlockchainPage;